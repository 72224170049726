/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Col, Row, Segmented, Spin } from 'antd';
import { useGetSnapshotsQuery } from 'api';
import type { TotalTokenBalanceSnapshot } from 'api/types';
import { BarChart } from 'components/charts/BarChart';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    ChartInterval,
    ETH_ADDRESS,
    GRAPH_ETH_ADDRESS,
    addressToToken,
    formatUnits,
} from 'utils';
import type { BarChartData, BarChartProps } from 'utils';

type TvlChartsProps = {
    tokenPrices: Record<string, number> | null;
    defaultChartInterval?: ChartInterval;
    isPreview?: boolean;
};

type Tokens = 'ETH' | 'USD' | 'ZK' | 'MEOW' | 'APPA';
type BarChartKeys = 'total' | 'idle' | 'invested' | Tokens;
type TvlType = 'total' | 'idle' | 'invested' | 'tokens';

export const TvlCharts = ({
    tokenPrices,
    defaultChartInterval = ChartInterval.Daily,
    isPreview = false,
}: TvlChartsProps): $MixedElement => {
    const [chartInterval, setChartInterval] =
        useState<ChartInterval>(defaultChartInterval);
    const [tvlType, setTvlType] = useState<TvlType>('total');

    const { data: snapshots, isLoading } = useGetSnapshotsQuery();

    const chartData = useMemo(() => {
        const daily: Array<BarChartData<BarChartKeys>> = [];
        const weekly: Array<BarChartData<BarChartKeys>> = [];
        const monthly: Array<BarChartData<BarChartKeys>> = [];

        if (
            !snapshots ||
            !tokenPrices ||
            snapshots.idle.length === 0 ||
            snapshots.invested.length === 0
        ) {
            return {
                [ChartInterval.Daily]: daily,
                [ChartInterval.Weekly]: weekly,
                [ChartInterval.Monthly]: monthly,
                [ChartInterval.Cumulative]: [],
            };
        }

        const processSnapshot = (
            snapshot: TotalTokenBalanceSnapshot,
            type: 'idle' | 'invested',
        ): void => {
            const date = new Date(snapshot.created_at).toDateString().slice(4);

            let dailyData = daily.find((d) => d.date === date);
            if (!dailyData) {
                dailyData = {
                    date,
                    total: 0,
                    idle: 0,
                    invested: 0,
                    ETH: 0,
                    USD: 0,
                    ZK: 0,
                    MEOW: 0,
                    APPA: 0,
                };
                daily.push(dailyData);
            }

            Object.entries(snapshot.data).forEach(([address, amount]) => {
                const tokenAddress =
                    address === GRAPH_ETH_ADDRESS ? ETH_ADDRESS : address;
                const token = addressToToken[tokenAddress];
                const decimal = token?.decimals ?? 18;
                const tokenAmount = formatUnits(amount, decimal);
                const tokenPrice = tokenPrices[tokenAddress] || 0;
                const usdValue = tokenAmount * tokenPrice;

                if (token?.symbol) {
                    let symbol = token.symbol;
                    if (['USDC', 'USDT', 'DAI', 'USDC.e'].includes(symbol)) {
                        symbol = 'USD';
                    }
                    dailyData[symbol as BarChartKeys] =
                        (dailyData[symbol as BarChartKeys] || 0) + usdValue;
                }

                dailyData[type] += usdValue;
                dailyData.total += usdValue;
            });
        };

        snapshots.idle.forEach((snapshot) => processSnapshot(snapshot, 'idle'));
        snapshots.invested.forEach((snapshot) =>
            processSnapshot(snapshot, 'invested'),
        );

        // Sort daily data by date
        daily.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        );

        // Process weekly data (last day of each week)
        let currentWeekStart = new Date(daily[0].date);
        daily.forEach((day, index) => {
            const currentDate = new Date(day.date);
            if (
                currentDate.getTime() - currentWeekStart.getTime() >=
                    7 * 24 * 60 * 60 * 1000 ||
                index === daily.length - 1
            ) {
                weekly.push(day);
                currentWeekStart = new Date(currentDate);
            }
        });

        // Process monthly data (last day of each month)
        let currentMonth = new Date(daily[0].date).getMonth();
        daily.forEach((day) => {
            const dayDate = new Date(day.date);
            if (dayDate.getMonth() !== currentMonth) {
                monthly.push({
                    ...day,
                    date: new Date(day.date).toLocaleString('default', {
                        month: 'short',
                        year: 'numeric',
                    }),
                });
                currentMonth = dayDate.getMonth();
            }
        });
        // Add the last month if not already added
        if (
            monthly[monthly.length - 1]?.date !== daily[daily.length - 1].date
        ) {
            monthly.push({
                ...daily[daily.length - 1],
                date: new Date(daily[daily.length - 1].date).toLocaleString(
                    'default',
                    {
                        month: 'short',
                        year: 'numeric',
                    },
                ),
            });
        }

        return {
            [ChartInterval.Daily]: daily.slice(-15),
            [ChartInterval.Weekly]: weekly,
            [ChartInterval.Monthly]: monthly,
            [ChartInterval.Cumulative]: [],
        };
    }, [snapshots, tokenPrices]);

    const barProps: BarChartProps<BarChartKeys> = {
        keys:
            tvlType === 'total'
                ? ['idle', 'invested']
                : tvlType === 'idle' || tvlType === 'invested'
                ? [tvlType]
                : ['ETH', 'USD', 'ZK', 'MEOW', 'APPA'],
        indexBy: 'date',
        axisBottomLegend: 'Date',
        axisLeftLegend: 'USD',
        enableTotals: true,
        valueFormat: ' >-$,.0f',
        groupMode: 'stacked' as const,
        legends:
            tvlType === 'tokens'
                ? [
                      {
                          dataFrom: 'keys',
                          anchor: 'bottom-right',
                          direction: 'column',
                          itemWidth: 120,
                          itemHeight: 24,
                          translateX: 120,
                          translateY: -50,
                          itemsSpacing: 8,
                          symbolSize: 16,
                          itemDirection: 'left-to-right',
                          itemTextColor: '#666666',
                          effects: [
                              {
                                  on: 'hover',
                                  style: {
                                      itemTextColor: '#000000',
                                  },
                              },
                          ],
                      },
                  ]
                : undefined,
    };

    if (isLoading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }

    return (
        <div className="w-[100%] min-h-[40vh] max-h-[100vh]">
            {!isPreview && (
                <Row>
                    <Col span={7}>
                        <Segmented
                            options={[
                                ChartInterval.Daily,
                                ChartInterval.Weekly,
                                ChartInterval.Monthly,
                            ]}
                            value={chartInterval}
                            defaultValue={defaultChartInterval}
                            onChange={(value): void => {
                                setChartInterval(value as ChartInterval);
                            }}
                        />
                    </Col>
                    <Col span={7}>
                        <Segmented
                            options={[
                                { label: 'Total', value: 'total' },
                                { label: 'Idle', value: 'idle' },
                                { label: 'Invested', value: 'invested' },
                                { label: 'Tokens', value: 'tokens' },
                            ]}
                            value={tvlType}
                            onChange={(value): void => {
                                setTvlType(value as TvlType);
                            }}
                        />
                    </Col>
                </Row>
            )}
            <BarChart data={chartData[chartInterval]} props={barProps} />
        </div>
    );
};
